
  <section class="wrapper image-wrapper bg-image bg-overlay text-white" style="background-image: url(&quot;/assets/video/blog.mp4&quot;);">
    <div class="video-wrap">
      <video src="/assets/video/blog.mp4" autoplay loop></video>
    </div>
    <div class="container pt-17 pb-13 pt-md-19 pb-md-17 text-center">
      <div class="row">
        <div class="col-md-10 col-xl-8 mx-auto">
          <div class="post-header">
            <!-- <div class="post-category text-line text-white">
              <a href="#" class="text-reset" rel="category"></a>
            </div> -->
            <h1 class="display-1 mb-4 text-white">
              {{label}}
            </h1>
            <!-- <ul class="post-meta text-white">
              <li class="post-date"><i class="uil uil-calendar-alt"></i><span>5 Jul 2021</span></li>
              <li class="post-author"><i class="uil uil-user"></i><a href="#" class="text-reset"><span>By Sandbox</span></a></li>
              <li class="post-comments"><i class="uil uil-comment"></i><a href="#" class="text-reset">3<span> Comments</span></a></li>
              <li class="post-likes"><i class="uil uil-heart-alt"></i><a href="#" class="text-reset">3<span> Likes</span></a></li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </section>