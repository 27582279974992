<section class="wrapper">
  <div class="container py-14">
    <div class="row gx-lg-0 gy-10 align-items-center">
      <div class="col-lg-12">
        <h2 class="display-1 mb-3">{{msglabel.default.TrustedPartner}}</h2>
        <p class="lead fs-lg">{{msglabel.default.WithSiemensPLMAutodeskandHinduja}}</p>
        <!-- <p class="mb-0">Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> -->
      </div>
      <!-- <div class="col-lg-8">
        <div class="row row-cols-2 row-cols-md-4 gx-0 gx-md-8 gx-xl-12 gy-12">
          <div class="col d-flex align-items-center" ngsRevealSet [ngsInterval]="200" [ngsSelector]="'.animate-img'"
            *ngFor="let Partner of Partners|slice:0:12">
            <figure class="animate-img px-3 px-md-0 px-xxl-2">
              <img [src]="Partner.Partner_name.logo" alt="">
            </figure>
          </div>

        </div>
      </div> -->

      <div class="col-lg-12 grid">
        <div class="row gx-md-5 gy-5 align-items-center counter-wrapper isotope">
          <div class="item col-md-4">
            <div class="card shadow-lg">
              <div class="card-body">
                <div class="d-flex d-lg-block d-xl-flex flex-row">
                  <div>
                    <div class="icon btn btn-circle btn-lg btn-soft-purple disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i
                        class="uil uil-users-alt"></i> </div>
                  </div>
                  <div>
                    <h3 class="counter mb-1">100+</h3>
                    <p class="mb-0">{{msglabel.default.Clients}}</p>
                  </div>
                </div>
              </div>
              <!--/.card-body -->
            </div>
            <!--/.card -->
          </div>
          <!--/column -->
          <div class="item col-md-4">
            <div class="card shadow-lg">
              <div class="card-body">
                <div class="d-flex d-lg-block d-xl-flex flex-row">
                  <div>
                    <div class="icon btn btn-circle btn-lg btn-soft-red disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i
                        class="uil uil-presentation-check"></i> </div>
                  </div>
                  <div>
                    <h3 class="counter mb-1">300+</h3>
                    <p class="mb-0">{{msglabel.default.Solutions}}</p>
                  </div>
                </div>
              </div>
              <!--/.card-body -->
            </div>
            <!--/.card -->
          </div>
          <!--/column -->
          <div class="item col-md-4">
            <div class="card shadow-lg">
              <div class="card-body">
                <div class="d-flex d-lg-block d-xl-flex flex-row">
                  <div>
                    <div class="icon btn btn-circle btn-lg btn-soft-yellow disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i
                        class="uil uil-map"></i> </div>
                  </div>
                  <div>
                    <h3 class="counter mb-1">20+</h3>
                    <p class="mb-0">{{msglabel.default.Countries}}</p>
                  </div>
                </div>
              </div>
              <!--/.card-body -->
            </div>
            <!--/.card -->
          </div>
          <!--/column -->
          <!-- <div class="item col-md-6">
              <div class="card shadow-lg">
                <div class="card-body">
                  <div class="d-flex d-lg-block d-xl-flex flex-row">
                    <div>
                      <div class="icon btn btn-circle btn-lg btn-soft-aqua disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i class="uil uil-trophy"></i> </div>
                    </div>
                    <div style="width: 100%;">
                      <h3 class="counter mb-1">90%</h3>
                      <p class="mb-0">Customer Retention</p>
                    </div>
                  </div>
                </div>
                
              </div>
              
            </div> -->
          <!--/column -->
        </div>
        <!--/.row -->
      </div>
      <!--/column -->

      <!--/column -->
    </div>
    <!--/.row -->
  </div>
  <!-- /.container -->
</section>
<!-- /section -->