<section class="wrapper image-wrapper bg-content what-we-do-wrap">
    <div class="container ">

        <div class="row gx-lg-0 gy-10 mb-15 mb-md-17 align-items-center">
            <div [ngsReveal]="{'duration': 2000}" class="col-lg-5">

                <h3 class="display-1 mb-3 text-white">{{msglabel.default.Whatwedo}}</h3>
                <!-- TODO:Montestrat <h4 class="text-white bold mb-8 subtitle"> -->
                <h4 class="text-white lead mb-8">
                    {{msglabel.default.nCircleisaonestop}} <a class="hover"
                        href="">{{msglabel.default.DDProductdevelopment}}</a>
                    {{msglabel.default.CenterforAECandManufacturing}}

                </h4>
                <ul class="icon-list bullet-bg bullet-soft-primary">
                    <li ngsRevealSet [ngsInterval]="500" [ngsSelector]="'.animate-title'" *ngFor="let item of whatWeDo">
                        <span class="animate-title">
                            <span>
                                <i class="uil uil-check"></i>
                            </span>
                            <span class="text-white">{{item.title}}</span>
                        </span>
                    </li>
                </ul>
                <a *ngIf="localstoragelanguage == 'EN'" routerLink="/EN/contact-us"
                    class="btn btn-primary custom-btn-border-radius mt-2">{{msglabel.default.GetaQuote}}</a>
                <a *ngIf="localstoragelanguage == 'JP'" routerLink="/JP/contact-us"
                    class="btn btn-primary custom-btn-border-radius mt-2">{{msglabel.default.GetaQuote}}</a>
                <a *ngIf="localstoragelanguage == 'IN'" routerLink="/contact-us"
                    class="btn btn-primary custom-btn-border-radius mt-2">{{msglabel.default.GetaQuote}}</a>
            </div>

            <div class="col-lg-6  offset-lg-1">
                <div class="row g-6 text-center">
                    <div class="col-md-6">
                        <div class="row">
                            <div [ngsReveal]="{'duration': 2000}" class="col-lg-12">
                                <figure class="rounded mb-6"><img style="height: 10rem !important;object-fit: cover;"
                                        src="assets/img/what-we-do/wwd05.jpg" alt=""></figure>
                            </div>
                            <div [ngsReveal]="{'duration': 3000}" class="col-lg-12 hide-icon">
                                <figure class="rounded mb-6"><img style="height: 15rem !important;object-fit: cover;"
                                        src="assets/img/what-we-do/wwd01.jpg" alt=""></figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 hide-icon">
                        <div class="row">
                            <div [ngsReveal]="{'duration': 4000}" class="col-lg-12 order-md-2">
                                <figure class="rounded mb-6 mb-md-0"><img
                                        style="height: 10rem !important;object-fit: cover;"
                                        src="assets/img/what-we-do/wwd04.jpg" alt=""></figure>
                            </div>
                            <div class="col-lg-12" [ngsReveal]="{'duration': 5000}">
                                <figure class="rounded mb-6"><img style="height: 15rem !important;object-fit: cover;"
                                        src="assets/img/what-we-do/wwd02.jpg" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>