<div class="post-footer d-md-flex flex-md-row justify-content-md-between align-items-center mt-8">
    <div>
        <ul class="list-unstyled tag-list mb-0">
            <li *ngFor="let cat of categories">
                <a class="btn btn-soft-ash btn-sm rounded-pill mb-0 " (click)="navigateToCategory(cat.slug)">
                    {{cat.name}}
                </a>
            </li>
        </ul>
    </div>
    <!-- <div class="mb-0 mb-md-2">
        <div class="dropdown share-dropdown btn-group">
            <button class="btn btn-sm btn-red rounded-pill btn-icon btn-icon-start dropdown-toggle mb-0 me-0"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="uil uil-share-alt"></i> Share </button>
            <div class="dropdown-menu">
                <a class="dropdown-item" href="#"><i class="uil uil-twitter"></i>Twitter</a>
                <a class="dropdown-item" href="#"><i class="uil uil-facebook-f"></i>Facebook</a>
                <a class="dropdown-item" href="#"><i class="uil uil-linkedin"></i>Linkedin</a>
            </div>
        </div>
    </div> -->
</div>