<article class="container custom-margin">


    <h1 class="h1" style="font-size: 2rem;">{{msglabel.default.Privacy}} &amp;{{msglabel.default.CookiePolicy}}</h1>


    <div class="entry-content">

        <p><strong>{{msglabel.default.nCircleTechPrivateLimitedCompany}}</strong></p>



        <p><strong>{{msglabel.default.PrivacyPolicy}}</strong></p>



        <p><strong>{{msglabel.default.Summary}}</strong></p>



        <p>{{msglabel.default.ProtectingyourprivacyisparamountimportantforCompanyThisPrivacyPolicyexplainshowpersonaldataiscollectedusedanddisclosedinrelationtowebsitesproductsandservicesServicesprovidedbyCompanyCompanyweandourandthatlinktothisPrivacyPolicy}}&nbsp;
        </p>



        <p>{{msglabel.default.ThisPrivacyPolicydescribeshowweprocesspersonaldataforourownpurposesWealsoprocesspersonaldataonbehalfofourcustomerssubjecttoawrittencontractWedonotcontrolthedataprocessingorprotectionpracticesofourcustomerswhichmaydifferfromthosesetoutinthisPrivacyPolicy}}
        </p>



        <p>{{msglabel.default.PersonaldataisinformationthatidentifiesorcanreasonablybelinkeddirectlyorindirectlytoanidentifiablepersonForpurposesofthisPrivacyPolicypersonaldataandpersonalinformationhavethesamemeaningandareusedinterchangeably}}
        </p>



        <p><em>{{msglabel.default.PrivacyCommitment}}</em></p>



        <p>{{msglabel.default.WeaskforonlytheleastamountofinformationnecessarygatheringonlywhatwebelieveisessentialfordoingbusinessorforthespecifictransactionathandWeletcustomersknowtheinformationwehaveonthemandallowthemtooptoutofspecificengagementsButbyfarourbiggestcommitmentisthatwedonotmakeasingledollarfromadvertisingrevenueneverhaveneverwillevenfromthefreeeditionsofourproductsThismeansweavoidthefundamentalconflictofinterestbetweengatheringcustomerinformationandfuelingadvertisingrevenueandtheunavoidablecompromisesincustomerprivacythatitbrings}}
        </p>



        <p>{{msglabel.default.Thegoalofthispolicyistomakeexplicittheinformationwegatheronourcustomersandusershowwewilluseitandhowwewillnot}}&nbsp;
        </p>



        <p>{{msglabel.default.ThisPrivacyPolicyisdividedintothreeparts}}</p>



        <p><em>{{msglabel.default.PartIInformationCompanycollectsandcontrols}}</em></p>



        <p>{{msglabel.default.ThispartdealswithhowCompanycollectsandusesinformationaboutwebsitevisitorspotentialcustomersusersofCompanysproductsandservicesandotherswhocontactCompanythroughformsoremailaddressespublishedonorlinkedtoourwebsites}}
        </p>



        <p><em>{{msglabel.default.PartIIInformationthatCompanyprocessesonyourbehalf}}</em></p>



        <p>{{msglabel.default.ThispartdealswithhowCompanyhandlesdatathatyouentrusttoCompanywhenyouuseourproductsandservicesorwhenyoushareanypersonalinformationorconfidentialinformationwithuswhilerequestingcustomersupport}}
        </p>



        <p><em>{{msglabel.default.PartIIIGeneral}}</em></p>



        <p>{{msglabel.default.ThispartdealswithtopicsthatarerelevanttobothPartsIandIIandothergeneraltopicssuchasCompanyssecuritycommitmentsandhowwewillinformyouwhenwechangethisPrivacyPolicy}}
        </p>



        <p><strong>{{msglabel.default.Policyindetail}}</strong></p>



        <p><strong>{{msglabel.default.PartIInformationCompanycollectandcontrols}}</strong></p>



        <p><em>{{msglabel.default.WhatinformationCompanycollects}}</em></p>



        <p>{{msglabel.default.WecollectinformationaboutyouonlyifweneedtheinformationforsomelegitimatepurposeCompanywillhaveinformationaboutyouonlyifayouhaveprovidedtheinformationyourselfbCompanyhasautomaticallycollectedtheinformationorcCompanyhasobtainedtheinformationfromathirdpartyBelowwedescribethevariousscenariosthatfallundereachofthosethreecategoriesandtheinformationcollectedineachone}}
        </p>



        <p><em>{{msglabel.default.Informationthatyouprovideus}}</em></p>



        <ul>
            <li><em>{{msglabel.default.Accountsignup}}</em>:
                {{msglabel.default.WhenyousignupforanaccounttoaccessoneormoreofourservicesweaskforinformationlikeyournamecontactnumberemailaddresscompanynameandcountrytocompletetheaccountsignupprocessYoullalsoberequiredtochooseauniqueusernameandapasswordforaccessingthecreatedaccountYoumayalsoprovideuswithmoreinformationsuchasyourphototimezoneandlanguagebutwedontrequirethatinformationtosignupforanaccountAftersigningupyouwillhavetheoptionofchoosingasecurityquestionandananswertothesecurityquestionifyouprovidethesetheywillbeusedonlywhileresettingyourpassword}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Eventregistrationsandotherformsubmissions}}
                </em>{{msglabel.default.WerecordinformationthatyousubmitwhenyouiregisterforanyeventincludingwebinarsorseminarsiisubscribetoournewsletteroranyothermailinglistiiisubmitaforminordertodownloadanyproductwhitepaperorothermaterialsivparticipateincontestsorrespondtosurveysorvsubmitaformtorequestcustomersupportortocontactCompanyforanyotherpurpose}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Paymentprocessing}}</em>:
                {{msglabel.default.WhenyoubuysomethingfromusweaskyoutoprovideyournamecontactinformationandcreditcardinformationorotherpaymentaccountinformationWhenyousubmityourcardinformationwestorethenameandaddressofthecardholdertheexpirydateandthelastfourdigitsofthecreditcardnumberWedonotstoretheactualcreditcardnumberForquickprocessingoffuturepaymentsifyouhavegivenusyourapprovalwemaystoreyourcreditcardinformationorotherpaymentinformationinanencryptedformatinthesecuredserversofourPaymentGatewayServiceProviders}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Testimonials}}</em>:
                {{msglabel.default.WhenyouauthorizeustoposttestimonialsaboutourproductsandservicesonwebsiteswemayincludeyournameandotherpersonalinformationinthetestimonialYouwillbegivenanopportunitytoreviewandapprovethetestimonialbeforewepostitIfyouwishtoupdateordeleteyourtestimonialyoucancontactusat}}<a
                    href="mailto:info@ncircletech.com">info@ncircletech.com</a>.
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.InteractionswithCompany}}</em>
                {{msglabel.default.Wemayrecordanalyzeanduseyourinteractionswithusincludingemailtelephoneandchatconversationswithoursalesandcustomersupportprofessionalsforimprovingourinteractionswithyouandothercustomers}}
            </li>
        </ul>



        <p>&nbsp;</p>



        <p><em>{{msglabel.default.Informationthatwecollectautomatically}}&nbsp;</em></p>



        <ul>
            <li><em>{{msglabel.default.Informationfrombrowsersdevicesandservers}}</em>:
                {{msglabel.default.WhenyouvisitourwebsiteswecollectinformationthatwebbrowsersmobiledevicesandserversmakeavailablesuchastheinternetprotocoladdressbrowsertypelanguagepreferencetimezonereferringURLdateandtimeofaccessoperatingsystemmobiledevicemanufacturerandmobilenetworkinformationWeincludetheseinourlogfilestounderstandmoreaboutvisitorstoourwebsites}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Informationfromfirstpartycookiesandtrackingtechnologies}}</em>:{{msglabel.default.WeusetemporaryandpermanentcookiestoidentifyusersofourservicesandtoenhanceuserexperienceWeembeduniqueidentifiersinourdownloadableproductstotrackusageoftheproductsWealsousecookiesbeaconstagsscriptsandothersimilartechnologiestoidentifyvisitorstrackwebsitenavigationgatherdemographicinformationaboutvisitorsandusersunderstandemailcampaigneffectivenessandfortargetedvisitoranduserengagementbytrackingyouractivitiesonourwebsitesWeonlyusefirstpartycookiesanddonotusethirdpartycookiesorotherthirdpartytrackingtechnologiesonourwebsites}}&nbsp;
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Informationfromapplicationlogsandmobileanalytics}}</em>:
                {{msglabel.default.WecollectinformationaboutyouruseofourproductsservicesandmobileapplicationsfromapplicationlogsandinhouseusageanalyticstoolsanduseittounderstandhowyourbusinessuseandneedscanimproveourproductsThisinformationincludesclicksscrollsfeaturesaccessedaccesstimeandfrequencyerrorsgeneratedperformancedatastorageutilizedusersettingsandconfigurationsanddevicesusedtoaccessandtheirlocations}}
            </li>
        </ul>



        <p>{{msglabel.default.Informationthatwecollectfromthirdparties}}&nbsp;</p>



        <ul>
            <li><em>{{msglabel.default.Signupsusingfederatedauthenticationserviceproviders}}</em>:
                {{msglabel.default.YoucanlogintoCompanyServicesusingsupportedfederatedauthenticationserviceproviderssuchasLinkedInMicrosoftandGoogleTheseserviceswillauthenticateyouridentityandgiveyoutheoptiontosharecertainpersonalinformationwithussuchasyournameandemailaddress}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Referrals}}</em>:{{msglabel.default.ncircletechcomtorequestthatweremoveyourinformationfromourdatabaseIfyouprovideusinformationaboutanotherpersonorifanotherpersongivesusyourinformationwewillonlyusethatinformationforthespecificreasonforwhichitwasprovidedtous}}
            </li>
        </ul>



        <p>&nbsp;</p>



        <ul>
            <li><em>{{msglabel.default.Informationfromourresellingpartnersandserviceproviders}}</em>:
                {{msglabel.default.IfyoucontactanyofourresellingpartnersorotherwiseexpressinterestinanyofourproductsorservicestothemtheresellingpartnermaypassyournameemailaddresscompanynameandotherinformationtoCompanyIfyouregisterfororattendaneventthatissponsoredbyCompanytheeventorganizermayshareyourinformationwithusCompanymayalsoreceiveinformationaboutyoufromreviewsitesifyoucommentonanyreviewofourproductsandservicesandfromotherthirdpartyserviceprovidersthatweengageformarketingourproductsandservices}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Informationfromsocialmediasitesandotherpubliclyavailablesources}}</em>:
                {{msglabel.default.WhenyouinteractorengagewithusonsocialmediasitessuchasFacebookTwitterGoogleandInstagramthroughpostscommentsquestionsandotherinteractionswemaycollectsuchpubliclyavailableinformationincludingprofileinformationtoallowustoconnectwithyouimproveourproductsorbetterunderstanduserreactionsandissuesWemusttellyouthatoncecollectedthisinformationmayremainwithusevenifyoudeleteitfromthesocialmediasitesCompanymayalsoaddandupdateinformationaboutyoufromotherpubliclyavailablesources}}
            </li>
        </ul>



        <p><em>{{msglabel.default.Purposesforusinginformation}}</em></p>



        <p>{{msglabel.default.Inadditiontothepurposesmentionedabovewemayuseyourinformationforthefollowingpurposes}}:</p>



        <ul>
            <li>{{msglabel.default.TocommunicatewithyousuchasthroughemailaboutproductsthatyouhavedownloadedandservicesthatyouhavesignedupforchangestothisPrivacyPolicychangestotheTermsofServiceorimportantnotices}}
            </li>
            <li>{{msglabel.default.Tokeepyoupostedonnewproductsandservicesupcomingeventsofferspromotionsandotherinformationthatwethinkwillbeofinteresttoyou}}
            </li>
            <li>{{msglabel.default.Toaskyoutoparticipateinsurveysortosolicitfeedbackonourproductsandservices}}</li>
            <li>{{msglabel.default.Tosetupandmaintainyouraccountandtodoallotherthingsrequiredforprovidingourservicessuchasenablingcollaborationprovidingwebsiteandmailhostingandbackingupandrestoringyourdata}}
            </li>
            <li>{{msglabel.default.Tounderstandhowusersuseourproductsandservicestomonitorandpreventproblemsandtoimproveourproductsandservices}}
            </li>
            <li>{{msglabel.default.Toprovidecustomersupportandtoanalyzeandimproveourinteractionswithcustomers}}</li>
            <li>{{msglabel.default.TodetectandpreventfraudulenttransactionsandotherillegalactivitiestoreportspamandtoprotecttherightsandinterestsofCompanyCompanysusersthirdpartiesandthepublic}}
            </li>
            <li>{{msglabel.default.Toupdateexpandandanalyzeourrecordsidentifynewcustomersandprovideproductsandservicesthatmaybeofinteresttoyou}}
            </li>
            <li>{{msglabel.default.Toanalyzetrendsadministerourwebsitesandtrackvisitornavigationsonourwebsitestounderstandwhatvisitorsarelookingforandtobetterhelpthem}}
            </li>
            <li>{{msglabel.default.Tomonitorandimprovemarketingcampaignsandmakesuggestionsrelevanttotheuser}}</li>
        </ul>



        <p><em>{{msglabel.default.Legalbasesforcollectingandusinginformation}}</em></p>



        <ul>
            <li><em>{{msglabel.default.LegalprocessingbasesapplicabletoCompany}}</em>:
                {{msglabel.default.IfyouareanindividualfromtheEuropeanEconomicAreaEEAourlegalbasisforinformationcollectionandusedependsonthepersonalinformationconcernedandthecontextinwhichwecollectitMostofourinformationcollectionandprocessingactivitiesaretypicallybasedoncontractualnecessityoneormorelegitimateinterestsofCompanyorathirdpartythatarenotoverriddenbyyourdataprotectioninterestsoryourconsentSometimeswemaybelegallyrequiredtocollectyourinformationormayneedyourpersonalinformationtoprotectyourvitalinterestsorthoseofanotherperson}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Withdrawalofconsent}}</em>:{{msglabel.default.Wherewerelyonyourconsentasthelegalbasisyouhavetherighttowithdrawyourconsentatanytimebutthiswillnotaffectanyprocessingthathasalreadytakenplace}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Legitimateinterestsnotice}}</em>:
                {{msglabel.default.Wherewerelyonlegitimateinterestsasthelegalbasisandthoselegitimateinterestsarenotspecifiedabovewewillclearlyexplaintoyouwhatthoselegitimateinterestsareatthetimethatwecollectyourinformation}}
            </li>
        </ul>



        <p><em>{{msglabel.default.Yourchoiceininformationuse}}</em></p>



        <ul>
            <li><em>{{msglabel.default.Optoutofnonessentialelectroniccommunications}}</em>:
                {{msglabel.default.YoumayoptoutofreceivingnewslettersandothernonessentialmessagesbyusingtheunsubscribefunctionincludedinallsuchmessagesHoweveryouwillcontinuetoreceivenoticesandessentialtransactionalemails}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Disablecookies}}</em>:
                {{msglabel.default.YoucandisablebrowsercookiesbeforevisitingourwebsitesHoweverifyoudosoyoumaynotbeabletousecertainfeaturesofthewebsitesproperly}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Optionalinformation}}</em>:
                {{msglabel.default.YoucanchoosenottoprovideoptionalprofileinformationsuchasyourphotoYoucanalsodeleteorchangeyouroptionalprofileinformationYoucanalwayschoosenottofillinnonmandatoryfieldswhenyousubmitanyformlinkedtoourwebsites}}
            </li>
        </ul>



        <p><em>{{msglabel.default.Whoweshareyourinformationwith}}</em></p>



        <ul>
            <li><em>{{msglabel.default.Employeesandindependentcontractors}}</em>:
                {{msglabel.default.EmployeesandindependentcontractorsofallCompanygroupentitieshaveaccesstotheinformationcoveredinPartIonaneedtoknowbasisWerequireallemployeesandindependentcontractorsofCompanygroupentitiestofollowthisPrivacyPolicyforpersonalinformationthatwesharewiththem}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Thirdpartyserviceproviders}}</em>:
                {{msglabel.default.WemayneedtoshareyourpersonalinformationandaggregatedordeidentifiedinformationwiththirdpartyserviceprovidersthatweengagesuchasmarketingandadvertisingpartnerseventorganizerswebanalyticsprovidersandpaymentprocessorsTheseserviceprovidersareauthorizedtouseyourpersonalinformationonlyasnecessarytoprovidetheseservicestous}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Resellingpartners}}</em>:
                {{msglabel.default.WemayshareyourpersonalinformationwithourauthorizedresellingpartnersinyourregionsolelyforthepurposeofcontactingyouaboutproductsthatyouhavedownloadedorservicesthatyouhavesignedupforWewillgiveyouanoptiontooptoutofcontinuingtoworkwiththatpartner}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Marketplaceapplicationdevelopers}}</em>:
                {{msglabel.default.WhenyouinstallorpurchaseanyapplicationdevelopedusingCompanysAPIsthatispostedonCompanysonlinemarketplaceyournameandemailaddresswillbesharedwiththedeveloperoftheapplicationsotheymayengagewithyoudirectlyastheproviderofthatapplicationorserviceCompanydoesnotcontroltheuseofyourpersonalinformationbythedeveloperswhichwillbebasedontheirownprivacypolicies}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Othercases}}</em>:
                {{msglabel.default.OtherscenariosinwhichwemaysharethesameinformationcoveredunderPartsIandIIaredescribedinPartIII}}
            </li>
        </ul>



        <p><em>{{msglabel.default.Yourrightswithrespecttoinformationweholdaboutyouasacontroller}}</em></p>



        <p>{{msglabel.default.IfyouareintheEuropeanEconomicAreaEEAyouhavethefollowingrightswithrespecttoinformationthatCompanyholdsaboutyouCompanyundertakestoprovideyouthesamerightsnomatterwhereyouchoosetolive}}
        </p>



        <ul>
            <li><em>{{msglabel.default.Righttoaccess}}</em>:
                {{msglabel.default.Youhavetherighttoaccessandobtainacopyofifrequiredthecategoriesofpersonalinformationthatweholdaboutyouincludingtheinformationssourcepurposeandperiodofprocessingandthepersonstowhomtheinformationisshared}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Righttorectification}}</em>:
                {{msglabel.default.YouhavetherighttoupdatetheinformationweholdaboutyouortorectifyanyinaccuraciesBasedonthepurposeforwhichweuseyourinformationyoucaninstructustoaddsupplementalinformationaboutyouinourdatabase}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Righttoerasure}}</em>:
                {{msglabel.default.Youhavetherighttorequestthatwedeleteyourpersonalinformationincertaincircumstancessuchaswhenitisnolongernecessaryforthepurposeforwhichitwasoriginallycollected}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Righttorestrictionofprocessing}}</em>:
                {{msglabel.default.Youmayalsohavetherighttorequesttorestricttheuseofyourinformationincertaincircumstancessuchaswhenyouhaveobjectedtoouruseofyourdatabutweneedtoverifywhetherwehaveoverridinglegitimategroundstouseit}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Righttodataportability}}</em>:{{msglabel.default.Youhavetherighttotransferyourinformationtoathirdpartyinastructuredcommonlyusedandmachinereadableformatincircumstanceswheretheinformationisprocessedwithyourconsentorbyautomatedmeans}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Righttoobject}}</em>:{{msglabel.default.Youhavetherighttoobjecttotheuseofyourinformationincertaincircumstancessuchastheuseofyourpersonalinformationfordirectmarketing}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Righttocomplain}}</em>:
                {{msglabel.default.YouhavetherighttocomplaintotheappropriatesupervisoryauthorityifyouhaveanygrievanceagainstthewaywecollectuseorshareyourinformationThisrightmaynotbeavailabletoyouifthereisnosupervisoryauthoritydealingwithdataprotectioninyourcountryRetentionofinformation}}
            </li>
        </ul>



        <p>{{msglabel.default.WeretainyourpersonalinformationforaslongasitisrequiredforthepurposesstatedinthisPrivacyPolicySometimeswemayretainyourinformationforlongerperiodsaspermittedorrequiredbylawsuchastomaintainsuppressionlistspreventabuseifrequiredinconnectionwithalegalclaimorproceedingtoenforceouragreementsfortaxaccountingortocomplywithotherlegalobligationsWhenwenolongerhavealegitimateneedtoprocessyourinformationwewilldeleteoranonymizeyourinformationfromouractivedatabasesWewillalsosecurelystoretheinformationandisolateitfromfurtherprocessingonbackupdiscsuntildeletionispossible}}
        </p>



        <p>&nbsp;</p>



        <p><strong>{{msglabel.default.PartIIInformationthatCompanyprocessesonyourbehalf}}</strong></p>



        <p><em>{{msglabel.default.InformationentrustedtoCompanyandpurpose}}</em></p>



        <ul>
            <li><em>{{msglabel.default.Informationprovidedinconnectionwithservices}}</em>:{{msglabel.default.YoumayentrustinformationthatyouoryourorganizationyoucontroltoCompanyinconnectionwithuseofourservicesorforrequestingtechnicalsupportforourproductsThisincludesinformationregardingyourcustomersandyouremployeesifyouareacontrollerordatathatyouholdanduseonbehalfofanotherpersonforaspecificpurposesuchasacustomertowhomyouprovideservicesifyouareaprocessorThedatamayeitherbestoredonourserverswhenyouuseourservicesortransferredorsharedtousaspartofarequestfortechnicalsupportorotherservices}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Informationfrommobiledevices}}</em>:{{msglabel.default.WhenyouelecttoallowitsomeofourmobileapplicationshaveaccesstothecameracallhistorycontactinformationphotolibraryandotherinformationstoredonyourmobiledeviceOurapplicationsrequiresuchaccesstoprovidetheirservicesSimilarlywhenyouelecttoprovideaccesslocationbasedinformationisalsocollectedforpurposesincludingbeexclusivelysharedwithourmappingprovidersandwillbeusedonlyformappinguserlocationsYoumaydisablethemobileapplicationsaccesstothisinformationatanytimebyeditingthesettingsonyourmobiledeviceThedatastoredonyourmobiledeviceandtheirlocationinformationtowhichthemobileapplicationshaveaccesswillbeusedinthecontextofthemobileapplicationandtransferredtoandassociatedwithyouraccountinthecorrespondingservicesinwhichcasethedatawillbestoredonourserversorproductsinwhichcasethedatawillremainwithyouunlessyoushareitwithus}}
            </li>
        </ul>



        <p>{{msglabel.default.AlltheinformationentrustedtoCompanyiscollectivelytermedservicedata}}</p>



        <p><em>{{msglabel.default.Ownershipandcontrolofyourservicedata}}</em></p>



        <p>{{msglabel.default.WerecognizethatyouownyourservicedataWeprovideyoucompletecontrolofyourservicedatabyprovidingyoutheabilitytoiaccessyourservicedataiishareyourservicedatathroughsupportedthirdpartyintegrationsandiiirequestexportordeletionofyourservicedata}}
        </p>



        <p><em>{{msglabel.default.Howweuseservicedata}}&nbsp;</em></p>



        <p>{{msglabel.default.WeprocessyourservicedatawhenyouprovideusinstructionsthroughthevariousmodulesofourservicesForexamplewhenyougenerateaninvoiceinformationsuchasthenameandaddressofyourcustomerwillbeusedtogeneratetheinvoiceandwhenyouuseourcampaignmanagementserviceforemailmarketingtheemailaddressesofthepersonsonyourmailinglistwillbeusedforsendingtheemails}}
        </p>



        <p><em>{{msglabel.default.Pushnotifications}}&nbsp;</em></p>



        <p>{{msglabel.default.IfyouhaveenablednotificationonourdesktopandmobileapplicationswewillpushnotificationsthroughapushnotificationprovidersuchasApplePushNotificationServiceGoogleCloudMessagingorWindowsPushNotificationServicesYoucanmanageyourpushnotificationpreferencesordeactivatethesenotificationsbyturningoffnotificationsintheapplicationordevicesettings}}
        </p>



        <p><em>{{msglabel.default.Whoweshareservicedatawith}}&nbsp;</em></p>



        <ul>
            <li><em>{{msglabel.default.Companygroupandthirdpartysubprocessors}}</em>:{{msglabel.default.InordertoprovideservicesandtechnicalsupportforourproductsthecontractingentitywithintheCompanygroupengagesthirdparties}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Employeesandindependentcontractors}}</em>:
                {{msglabel.default.WemayprovideaccesstoyourservicedatatoouremployeesandindividualswhoareindependentcontractorsoftheCompanygroupentitiesinvolvedinprovidingtheservicescollectivelyouremployeessothattheycaniidentifyanalyzeandresolveerrorsiimanuallyverifyemailsreportedasspamtoimprovespamdetectionoriiimanuallyverifyscannedimagesthatyousubmittoustoverifytheaccuracyofopticalcharacterrecognitionWeensurethataccessbyouremployeestoyourservicedataisrestrictedtospecificindividualsandisloggedandauditedOuremployeeswillalsohaveaccesstodatathatyouknowinglysharewithusfortechnicalsupportortoimportdataintoourproductsorservicesWecommunicateourprivacyandsecurityguidelinestoouremployeesandstrictlyenforceprivacysafeguardswithintheCompanygroup}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Collaboratorsandotherusers}}</em>:
                {{msglabel.default.SomeofourproductsorservicesallowyoutocollaboratewithotherusersorthirdpartiesInitiatingcollaborationmayenableothercollaboratorstoviewsomeorallofyourprofileinformationForexamplewhenyoueditadocumentthatyouhavesharedwithotherpersonsforcollaborationyournameandprofilepicturewillbedisplayednexttoyoureditstoallowyourcollaboratorstoknowthatyoumadethoseedits}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Thirdpartyintegrationsyouhaveenabled}}</em>:
                {{msglabel.default.MostofourproductsandservicessupportintegrationswiththirdpartyproductsandservicesIfyouchoosetoenableanythirdpartyintegrationsyoumaybeallowingthethirdpartytoaccessyourserviceinformationandpersonalinformationaboutyouWeencourageyoutoreviewtheprivacypracticesofthethirdpartyservicesandproductsbeforeyouenableintegrationswiththem}}
            </li>
        </ul>



        <ul>
            <li><em>{{msglabel.default.Othercases}}</em>:
                {{msglabel.default.OtherscenariosinwhichwemayshareinformationthatarecommontoinformationcoveredunderPartsIandIIaredescribedinPartIII}}
            </li>
        </ul>



        <p><em>{{msglabel.default.Retentionofinformation}}&nbsp;</em></p>



        <p>{{msglabel.default.WeholdthedatainyouraccountaslongasyouchoosetouseCompanyServicesOnceyouterminateyourCompanyuseraccountyourdatawilleventuallygetdeletedfromactivedatabaseduringthenextcleanupthatoccursannuallyThedatadeletedfromactivedatabasewillbedeletedfrombackupsaftermonths}}
        </p>



        <p><em>{{msglabel.default.Datasubjectrequests}}</em></p>



        <p>{{msglabel.default.IfyouarefromtheEuropeanEconomicAreaandyoubelievethatwestoreuseorprocessyourinformationonbehalfofoneofourcustomerspleasecontactthecustomerifyouwouldliketoaccessrectifyeraserestrictorobjecttoprocessingorexportyourpersonaldataWewillextendoursupporttoourcustomerinrespondingtoyourrequestwithinareasonabletimeframe}}
        </p>



        <h3><strong>{{msglabel.default.PartIIIGeneral}}</strong></h3>



        <h3><em>{{msglabel.default.Childrenspersonalinformation}}</em></h3>



        <p>{{msglabel.default.ThisAppdoesnotaddresschildrenWedonotknowinglycollectpersonalidentifiableinformationfromchildrenunderthirteenWewillonlyuseordisclosepersonaldataaboutachildtotheextentpermittedbylawtoseekparentalconsentpursuanttolocallawandregulationsortoprotectachildThedefinitionofchildorchildrenshouldtakeintoaccountapplicablelawsaswellasnationalandregionalculturalcustoms}}
        </p>



        <h3><em>{{msglabel.default.Externallinksonourwebsites}}</em></h3>



        <p>{{msglabel.default.SomepagesofourwebsitesmaycontainlinkstowebsitesthatarenotlinkedtothisPrivacyPolicyIfyousubmityourpersonalinformationtoanyofthesethirdpartysitesyourpersonalinformationisgovernedbytheirprivacypoliciesAsasafetymeasurewerecommendthatyounotshareanypersonalinformationwiththesethirdpartiesunlessyouvecheckedtheirprivacypoliciesandassuredyourselfoftheirprivacypractices}}
        </p>



        <p><em>{{msglabel.default.Cookies}}&nbsp;</em></p>



        <p>{{msglabel.default.WhenyouviewourwebsiteorlinksreferredtheretoweorourthirdpartycodemaystoresomedataonyoursystemintheformofacookietoautomaticallyrecognizeyoursystemthenexttimeyouvisitandimproveupontheservicesbeingprovidedCookiescanhelpusinmanywaysforexamplebyallowingustotailortheAppexperiencetobettermatchyourinterestsortostoreyourpasswordtosaveyouhavingtoreenteriteachtimeIfyoudonotwishtoreceivecookiespleaseconfigureyourInternetbrowsertoeraseallcookiesfromyoursystemsmemoryorharddriveblockallcookiesortoreceiveawarningbeforeacookieisstored}}&nbsp;
        </p>



        <p>{{msglabel.default.YouhavetheoptiontoeitheracceptorrefusethesecookiesandknowwhenacookieisbeingsenttoyourdeviceIfyouchoosetorefuseourcookiesyoumaynotbeabletousesomeportionsoftheservice}}&nbsp;
        </p>



        <h3><em>{{msglabel.default.Blogsandforums}}</em></h3>



        <p>{{msglabel.default.WeofferpubliclyaccessibleblogsandforumsonourwebsitesPleasebeawarethatanyinformationyouprovideontheseblogsandforumsmaybeusedtocontactyouwithunsolicitedmessagesWeurgeyoutobecautiousindisclosingpersonalinformationinourblogsandforumsCompanyisnotresponsibleforthepersonalinformationyouelecttodisclosepubliclyYourpostsandcertainprofileinformationmayremainevenafteryouterminateyouraccountwithCompanyTorequesttheremovalofyourinformationfromourblogsandforumsyoucancontactusat}}
            <a href="mailto:info@ncircletech.com">info@ncircletech.com</a>
        </p>



        <p><a href="mailto:privacy@zohocorp.com">&nbsp;</a></p>



        <h3><em>{{msglabel.default.Socialmediawidgets}}</em></h3>



        <p>{{msglabel.default.OurwebsitesincludesocialmediawidgetssuchasFacebooklikebuttonsandTwittertweetbuttonsthatletyousharearticlesandotherinformationThesewidgetsmaycollectinformationsuchasyourIPaddressandthepagesyounavigateinthewebsiteandmaysetacookietoenablethewidgetstofunctionproperlyYourinteractionswiththesewidgetsaregovernedbytheprivacypoliciesofthecompaniesprovidingthem}}
        </p>



        <h3><em>{{msglabel.default.Disclosuresincompliancewithlegalobligations}}</em></h3>



        <p>{{msglabel.default.Wemayberequiredbylawtopreserveordiscloseyourpersonalinformationandservicedatatocomplywithanyapplicablelawregulationlegalprocessorgovernmentalrequestincludingtomeetnationalsecurityrequirements}}
        </p>



        <h3><em>{{msglabel.default.Enforcementofourrights}}</em></h3>



        <p>{{msglabel.default.Wemaydisclosepersonalinformationandservicedatatoathirdpartyifwebelievethatsuchdisclosureisnecessaryforpreventingfraudinvestigatinganysuspectedillegalactivityenforcingouragreementsorpoliciesorprotectingthesafetyofourusers}}
        </p>



        <h3><em>{{msglabel.default.BusinessTransfers}}</em></h3>



        <p>{{msglabel.default.WedonotintendtosellourbusinessHoweverintheunlikelyeventthatwesellourbusinessorgetacquiredormergedwewillensurethattheacquiringentityislegallyboundtohonorourcommitmentstoyouWewillnotifyyouviaemailorthroughaprominentnoticeonourwebsiteofanychangeinownershiporintheusesofyourpersonalinformationandservicedataWewillalsonotifyyouaboutanychoicesyoumayhaveregardingyourpersonalinformationandservicedata}}
        </p>



        <h3><em>{{msglabel.default.CompliancewiththisPrivacyPolicy}}</em></h3>



        <p>{{msglabel.default.WemakeeveryeffortincludingperiodicreviewstoensurethatpersonalinformationyouprovideisusedinconformitywiththisPrivacyPolicyIfyouhaveanyconcernsaboutouradherencetothisPrivacyPolicyorthemannerinwhichyourpersonalinformationisusedkindlywritetousat}}<a
                href="mailto:info@ncircletech.com">info@ncircletech.com</a>.&nbsp;
            {{msglabel.default.Wewillcontactyouandifrequiredcoordinatewiththeappropriateregulatoryauthoritiestoeffectivelyaddressyourconcerns}}
        </p>



        <h3><em>{{msglabel.default.Notificationofchanges}}</em></h3>



        <p>{{msglabel.default.WemayupdateourPrivacyPolicyfromtimetotimeThusyouareadvisedtoreviewthispageperiodicallyforanychangesWewillnotifyyouofanychangesbypostingthenewPrivacyPolicyonthispageThesechangesareeffectiveimmediatelyaftertheyarepostedonthispage}}
        </p>
    </div> <!-- .entry-content -->


</article>